<template>
    <div class="search__input w-100 px-2">
        <b-input-group>
            <b-form-input id="search-product" ref="searchProduct" :value="modelValue" @input="onInput"
                placeholder="Cari nama produk" />
            <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" />
            </b-input-group-append>
            <b-input-group-append>
                <b-button @click="toggleBarcodeScanner">
                    {{ !showBarcodeScanner ? 'Scan With Camera' : 'Stop Scan' }}
                </b-button>
            </b-input-group-append>
            <b-input-group-append>
                <b-button variant="success" @click="handleScanWithScanner">
                    Scan With Scanner
                </b-button>
            </b-input-group-append>
        </b-input-group>
        <div class="my-1">
            <StreamBarcodeReader ref="scanner" class="" v-if="showBarcodeScanner" @decode="onDecode" @loaded="onLoaded"
                style="width: 20rem;" />
        </div>
    </div>
</template>

<script>
import { BInputGroup, BInputGroupAppend, BFormInput, BButton } from 'bootstrap-vue';
import { StreamBarcodeReader } from 'vue-barcode-reader'

export default {
    components: {
        BInputGroup, BInputGroupAppend, BFormInput, BButton, StreamBarcodeReader
    },
    props: {
        modelValue: {
            type: String,
        },
    },
    data() {
        return {
            showBarcodeScanner: false,
            barcode_number: null
        }
    },
    methods: {
        handleScanWithScanner() {
            this.$refs.searchProduct.focus()
        },
        toggleBarcodeScanner() {
            if (this.showBarcodeScanner) {
                this.stopBarcodeScanner()
            }
            this.showBarcodeScanner = !this.showBarcodeScanner

        },
        onDecode(result) {
            this.$emit("input", result)
        },
        stopBarcodeScanner() {
            this.$refs.scanner?.codeReader?.stream
                .getTracks()
                .forEach(function (track) {
                    track.stop();
                });
        },
        onLoaded() {
            console.log('Barcode reader loaded')
            alert('Barcode reader loaded')

        },
        onInput(e) {
            this.$emit("input", e)
        }
    },
}
</script>