<template>
  <div class="table__container">
    <div class="transaction__filter--container bg-white py-2">
      <div class="d-flex align-items-center search__export w-100">
        <!-- <SearchWithScanner v-model="filter.search" :modelValue="filter.search" /> -->
        <div class="search__input w-100 px-2">
          <b-input-group>
            <b-form-input v-model="filter.search" placeholder="Cari nama produk" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mb-1 mt-5" v-if="isLoading == true">
      <b-spinner label="Loading..." />
    </div>

    <vue-good-table v-else class="bg-white" max-height="80vh" :columns="columns" :rows="rows.data" :fixed-header="false"
      :sort-options="{
        enabled: false,
      }" :pagination-options="{
        enabled: false,
      }" :select-options="{
        enabled: true,
      }" @on-selected-rows-change="selectionChanged">
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />

        <span v-else-if="props.column.field == 'avg_purchase_price'">
          <span v-if="checkPermission('harga modal')">
            {{ props.column.label }}
          </span>
        </span>

        <span v-else-if="props.column.field == 'formatted_id'">
          <div class="d-flex">
            <span class="mr-1">
              {{ props.column.label }}
            </span>
            <b-img class="filter-image" :class="sortType[0].sortBy == 'asc' ? 'rotate-180' : ''"
              :src="require('@/assets/images/icons/Icon-order-list-down.svg')" alt="Icon-order-list-down" role="button"
              @click="handleSort('id', 'desc')" />
          </div>
        </span>
        <span v-else-if="props.column.field == 'name'">
          <div class="d-flex">
            <span class="mr-1">
              {{ props.column.label }}
            </span>
            <b-img class="filter-image" :class="sortType[1].sortBy == 'asc' ? 'rotate-180' : ''"
              :src="require('@/assets/images/icons/Icon-order-list-down.svg')" alt="Icon-order-list-down" role="button"
              @click="handleSort('name', 'desc')" />
          </div>
        </span>

        <span v-else>
          <span>
            {{ props.column.label }}
          </span>
        </span>
      </template>

      <template slot="table-row" slot-scope="props">

        <span v-if="props.column.field === 'standard_price'">
          <span>
            {{ props.row.standard_price | formatAmount }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'warehouse_names'" id="warehouse_names">
          <div v-if="props.row.warehouse_names">
            <span v-if="props.row.warehouse_names.length <= 3">{{ props.row.warehouse_names.map(e => e).slice(0,
              3).join(', ') }}</span>
            <b-dropdown v-else :text="props.row.warehouse_names.map(e => e).slice(0, 3).join(', ')"
              variant="flat-secondary px-0" class="px-0">
              <b-row class="mx-0 pt-1">
                <b-col v-for="(item, index) in props.row.warehouse_names" :key="index" class="">
                  <h6 class="text-dark">{{ item }}</h6>
                </b-col>
              </b-row>
            </b-dropdown>
          </div>
        </span>

        <span v-else-if="props.column.field === 'item'">
          {{ props.row.item ? props.row.item.name : '-' }}
        </span>

        <span v-else-if="props.column.field === 'avg_purchase_price'">
          <span v-if="checkPermission('harga modal')">
            {{ props.row.avg_purchase_price | formatAmount }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'minimum_price'">
          <span>
            {{ props.row.minimum_price | formatAmount }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'barcode_number'">
          {{ props.row.barcode_number ? 'Available' : 'Not Available' }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span v-if="checkPermission('update produk') | checkPermission('delete produk')">
            <b-dropdown id="dropdown-dropleft" class="d-flex" right>
              <template #button-content class="btn-white text-center">
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle" />
              </template>
              <b-dropdown-item @click="goToDetail(props)" v-if="checkPermission('update produk')">
                <span>
                  Edit
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteData(props.row.uuid)" v-if="checkPermission('delete produk')">
                <span>
                  Hapus
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border" style="padding: 0.8rem;">
      <div class="d-flex align-items-center">
        <b-form-select v-model="filter.per_page" :options="['10', '15', '20']" class="mx-1" />
        <span class="text-nowrap">
          Tampilkan {{ (rows.total > 0) ? (filter.per_page * (rows.current_page - 1)) + 1 : 0 }} ke
          {{ (rows.total > 0) ? Math.min(filter.per_page * rows.current_page, rows.total) : 0 }} dari {{ rows.total }}
          transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination class="mb-0" :data="rows" :limit="4" align="right" @pagination-change-page="getDataProduct" />
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- Preview Print Barcode -->
    <b-modal id="form-export" size="lg" title="Preview Print">
      <vue-html2pdf :enable-download="false" :preview-modal="true" ref="html2Pdf" :float-layout="false"
        :manual-pagination="true" filename="export-barcode" :pdf-quality="2" :html-to-pdf-options="{
          html2canvas: {
            scale: 4,
            letterRendering: true,
            useCORS: true
          },
          jsPDF: {
            unit: 'mm',
            format: paperSizes[selectedOptionLabel],
            orientation: selectedOptionLabel === '1' ? 'landscape' : 'portrait'
          }
        }">
        <section slot="pdf-content" id="contentToPrint">
          <!-- style="padding-top: 2mm; padding-left: 2mm;gap: 2mm;max-width: 46mm" -->
          <div class="d-flex flex-wrap" :class="{ 'flex-column': selectedOptionLabel === '1' }" :style="labelCardStyle[selectedOptionLabel]">
            <!-- <div v-for="item, index in barcode_numbers" :key="index" :style="styleLabel[selectedOptionLabel]"> -->
            <!-- + selectedOptionLabel === '1' ? 'padding-left: 1mm' : ''" -->
            <div v-for="item, index in barcode_numbers" :key="index" :style="`${styleLabel[selectedOptionLabel]};${selectedOptionLabel === '1' ? 'padding-left: 1mm' : ''}`">
              <div class="text-center">
                <h1
                  :style="`font-size: ${textLabelStyle[selectedOptionLabel]};color: black;margin-bottom: 0px;padding-top:2mm`">
                  {{ item.name }}</h1>
                <!-- <b-img :src="`https://barcodeapi.org/api/${item.barcode_number}`" /> -->
                <vue-barcode :value="item.barcode_number" element-tag="img" :text="`${item.barcode_number}`"
                  :class="`label-${selectedOptionLabel}-line`">
                  Render failed
                </vue-barcode>
              </div>
              <div class="html2pdf__page-break" v-if="(index + 1) % pageBreakLabel[selectedOptionLabel] === 0" />
            </div>
          </div>
        </section>
      </vue-html2pdf>
      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <b-button variant="success" :disabled="isLoading" @click="generateReport">
          Download Barcode
        </b-button>
      </template>
    </b-modal>

    <!-- Barcode QTY -->
    <b-modal id="modal-barcode-qty" size="lg" title="Jumlah Barcode">
      <div class="p-1">
        <label for="qty">Barcode Qty</label>
        <b-form-spinbutton id="qty" v-model="barcode_qty" min="1" />
      </div>
      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <b-button variant="success" :disabled="isLoading" @click="handleBarcodeQty">
          Go To Download Barcode
        </b-button>
      </template>
    </b-modal>

    <!-- Setting Layout Print -->
    <b-modal id="modal-setting-layout-print" size="lg" title="Setting Layout Print">
      <div class="p-1">
        <label for="label">Ukuran Label</label>
        <b-form-select v-model="selectedOptionLabel" :options="optionLabels" id="label" />
      </div>
      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <b-button variant="success" :disabled="isLoading" @click="handlePrintBarcode">
          Next
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BDropdown, BDropdownItem, BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BRow, BCol, BSpinner, BButton, BFormSpinbutton
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import { VueGoodTable } from 'vue-good-table'

import VueHtml2pdf from 'vue-html2pdf'
import VueBarcode from 'vue-barcode'
import SearchWithScanner from '@/components/Search/SearchWithScanner.vue'

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BRow,
    BCol,
    BSpinner,
    VueHtml2pdf,
    VueBarcode,
    BButton,
    BFormSpinbutton,
    SearchWithScanner
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: 'ID',
          field: 'formatted_id',
        },
        // {
        //   label: 'SKU',
        //   field: 'sku_code',
        // },
        {
          label: 'Nama Produk',
          field: 'name',
        },
        // {
        //   label: 'Nama Alias',
        //   field: 'alias_name',
        // },
        {
          label: 'Lokasi SKU',
          field: 'warehouse_names',
        },
        {
          label: 'Harga Modal',
          field: 'avg_purchase_price',
        },
        {
          label: 'Harga Minimum',
          field: 'minimum_price',
        },
        {
          label: 'Harga Standar',
          field: 'standard_price',
        },
        {
          label: 'Parent Kategori',
          field: 'item',
        },
        {
          label: 'Barcode',
          field: 'barcode_number',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      formPayload: {
        product_type: '',
        image: '',
        item_uuid: '',
        specification: '',
        uom_uuid: '',
        alias_name: '',
        sku_code: 0,
        standard_price: 0,
        minimum_price: 0,
        discount_percents: [],
        discount_fixed: 0,
        discount_start_date: '',
        discount_end_date: '',
        warehouses_uuid: [],
      },
      filter: {
        search: '',
        per_page: 10,
        sort_type: 'desc',
      },
      page: 1,
      uuid: null,
      sortType: [
        {
          type: 'id',
          sortBy: 'desc'
        },
        {
          type: 'name',
          sortBy: 'desc'
        },
      ],
      uuids: [],
      barcode_numbers: [],
      barcode_qty: 1,
      paperSizes: {
        '1': [33, 15],
        '1x50': [50, 30],
        '2': [72, 107],
        '3': [72, 107]
      },
      selectedOptionLabel: null,
      optionLabels: [
        { value: null, text: 'Please select an option' },
        { value: '1', text: 'Label 1 Line (33mm x 15mm)' },
        { value: '1x50', text: 'Label 1 Line (50mm x 20mm)' },
        { value: '2', text: 'Label 2 Line (48mm x 33mm)' },
        { value: '3', text: 'Label 3 Line (33mm x 15mm)' },
      ],
      styleLabel: {
        '1': 'max-width: 33mm; max-height: 15mm;',
        '1x50': 'max-width: 50mm; max-height: 30mm;',
        '2': 'max-width: 48mm; max-height: 33mm;',
        '3': 'max-width: 33mm; max-height: 15mm;'
      },
      pageBreakLabel: {
        '1': 1,
        '1x50': 1,
        '2': 6,
        '3': 6 
      },
      textLabelStyle: {
        '1': '5px',
        '1x50': '7px',
        '2': '10px',
        '3': '7px' 
      },
      labelCardStyle: {
        '1': 'max-width: 33mm;padding-top: 0; padding-left: 0;gap: 0;',
        '1x50': 'max-width: 50mm;padding-top: 0; padding-left: 0;gap: 0;',
        '2': 'max-width: 72mm;padding-top: 2mm; padding-left: 2mm;gap: 2mm;',
        '3': 'max-width: 72mm;padding-top: 2mm; padding-left: 2mm;gap: 2mm;' 
      }
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getDataProduct()
      }, 300),
      deep: true,
    },
    uuids: {
      handler(uuids) {
        this.barcode_numbers = []
        uuids.forEach(uuid => {
          if (uuid) {
            const item = this.rows.data.find(item => item.uuid === uuid);
            if (item && item.barcode_number) {
              // Check if the barcode number is already in the array
              const exists = this.barcode_numbers.some(
                existing => existing.barcode_number === item.barcode_number
              );

              if (!exists) {
                this.barcode_numbers.push({
                  barcode_number: item.barcode_number,
                  name: item.name
                });
              }
            }
          }
        });
      },
      deep: true,
    }
  },
  created() {
    this.getDataProduct()
  },
  methods: {
    ...mapActions('parentProduct', ['getData']),
    printElement(elementId) {
      // // Get the content of the specified element
      // const content = document.getElementById(elementId).innerHTML;

      // // Create a new window for printing
      // const printWindow = window.open('', '', 'height=600,width=800');

      // // Write the content to the new window
      // printWindow.document.write('<html><head><title>Print Content</title>');
      // printWindow.document.write('<style>body{font-family: Arial, sans-serif;}</style>'); // Optional: add styles
      // printWindow.document.write('</head><body>');
      // printWindow.document.write(content);
      // printWindow.document.write('</body></html>');

      // // Close the document to finish loading the content
      // printWindow.document.close();

      // // Print the content
      // printWindow.print();

      // const element = this.$refs.contentToPrint;
      // const options = {
      //   margin: 1,
      //   filename: 'custom-size.pdf',
      //   image: { type: 'jpeg', quality: 0.98 },
      //   html2canvas: { scale: 2 },
      //   jsPDF: { unit: 'in', format: [8.5, 11], orientation: 'portrait' }, // Custom paper size [width, height]
      // };
      // html2pdf().from(element).set(options).save();
    },
    handlePrintBarcode() {
      this.$bvModal.hide('modal-setting-layout-print')
      if (this.uuids.length === 1) {
        this.$bvModal.show('modal-barcode-qty')
      } else {
        this.$bvModal.show('form-export')
      }
    },
    openModalLayoutSetting() {
      if (this.uuids.length === 0) {
        errorNotification(this, 'Oops!', 'Silahkan pilih item terlebih dahulu.')
        return;
      }
      this.$bvModal.show('modal-setting-layout-print')
    },
    selectionChanged(event) {
      this.uuids = event.selectedRows.map(item => item.uuid)
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    handleBarcodeQty() {
      this.barcode_numbers = []
      if (this.uuids[0]) {
        const item = this.rows.data.find(item => item.uuid === this.uuids[0]);
        for (let index = 0; index < this.barcode_qty; index++) {
          this.barcode_numbers.push({
            barcode_number: item.barcode_number,
            name: item.name
          })
        }
      }
      if (this.barcode_numbers[0].barcode_number !== null) {
        this.$bvModal.hide('modal-barcode-qty')
        this.$bvModal.show('form-export')
      } else {
        errorNotification(this, 'Oops!', 'Barcode belum tersedia.')
        this.$bvModal.hide('modal-barcode-qty')
      }
    },
    handleSort(type, currentSortOrder, page = this.$route.query.page || 1) {
      // Toggle sort order based on the current sort order
      const sortOrder = currentSortOrder === 'asc' ? 'desc' : 'asc';

      // Construct the query params for the API call
      const queryParams = {
        page,
        sort_column: type,
        sort_type: sortOrder
      };

      // Update the component's state for sorting
      this.page = page;
      this.sortType = this.sortType.map(item => {
        if (item.type === type) {
          const newSortOrder = item.sortBy === 'asc' ? 'desc' : 'asc';
          queryParams.sort_type = newSortOrder
          return {
            ...item,
            sortBy: newSortOrder // Update sortBy for the matching type
          };
        }
        return item; // Return the other types unchanged
      });

      // Fetch data with the updated sort options
      this.getData({ params: queryParams, uuid: '' })
        .then(result => {
          this.rows = result.data.data;
          this.isLoading = false;
          this.uuid = null;
        })
        .catch(err => {
          if (err.response?.data?.meta?.messages) {
            errorNotification(this, 'Oops!', err.response.data.meta.messages);
          }
          this.isLoading = false;
          console.error(err);
        });
    },
    goToDetail(props) {
      // console.log(props);
      this.$router.push({ name: 'master-product.detail', params: { id: props.row.uuid }, query: { page: this.page || 1 } })
    },
    getDataProduct(page = this.$route.query.page || 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page
      this.page = page
      this.getData({ params: queryParams, uuid: '' })
        .then(result => {
          this.rows = result.data.data
          this.isLoading = false
          this.uuid = null
        }).catch(err => {
          if (err.response.data.meta.messages) {
            errorNotification(this, 'Oops!', err.response.data.meta.messages)
          }
          this.isLoading = false
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    clearItem() {
      this.$store.commit('parentProduct/setEditId', null)
      this.$store.commit('parentProduct/setFormPayload', this.formPayload = {
        product_type: '',
        image: '',
        item_uuid: '',
        specification: '',
        uom_uuid: '',
        alias_name: '',
        sku_code: 0,
        standard_price: 0,
        minimum_price: 0,
        discount_percents: [],
        discount_fixed: 0,
        discount_start_date: '',
        discount_end_date: '',
        warehouses_uuid: [],
      })
    },
    detailItem(item) {
      this.$bvModal.show('modal-customer')
      this.isEdit = false
      this.$store.commit('parentProduct/setResult', item)
    },
    async deleteData(uuid) {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apa Anda yakin untuk menghapus produk induk ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('parentProduct/deleteData', `/${uuid}`)
            .then(() => {
              this.getDataProduct()
              this.$swal({
                icon: 'success',
                title: 'Sukses!',
                text: 'Produk induk berhasil dihapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    async editItem(item) {
      await this.clearItem()
      this.uuid = item.uuid
      this.$store.commit('parentProduct/setEditId', item.uuid)
      this.$store.commit('parentProduct/setFormPayload', this.formPayload = {
        product_type: 'parent',
        brand_uuid: item.brand_uuid,
        item_uuid: item.item.uuid,
        specification: item.specification,
        uom_uuid: item.uom.uuid,
        alias_name: item.alias_name,
        sku_code: item.sku_code,
        standard_price: item.standard_price,
        minimum_price: item.minimum_price,
        discount_percents: item.discount_percents.map(o => o),
        discount_fixed: item.discount_fixed,
        discount_start_date: item.discount_start_date,
        discount_end_date: item.discount_end_date,
        // warehouses_uuid: [],
      })
      this.$bvModal.show('modal-add-product-parent')
    },
  },
}
</script>

<style lang="scss">

.label-1x50-line {
  .vue-barcode-element {
    width: 50mm;
    height: 25mm;
  }
}
.label-1-line {
  .vue-barcode-element {
    width: 33mm;
    height: 10mm;
  }
}
.label-2-line {
  .vue-barcode-element {
    width: 33mm;
  }
}
.label-3-line {
  .vue-barcode-element {
    width: 22mm;
    height: 13mm;
    object-fit: cover;
  }
}

table {
  &.vgt-table {
    border: none;
  }

  th {
    z-index: 1000;
  }
}

.vgt-table thead th.vgt-checkbox-col {
  vertical-align: middle;
  position: sticky;
  top: 0;
  z-index: 1001;
  background: white;
}

.vgt-responsive {
  height: calc(100vh - 322px) !important;
  background-color: #fff;
}
</style>
